import 'owl.carousel';
// getCookie(), setCookie(), deleteCookie()


// возвращает cookie если есть или undefined
function getCookie(name) {

		var matches = document.cookie.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		))
		return matches ? decodeURIComponent(matches[1]) : undefined
}

// уcтанавливает cookie
function setCookie(name, value, props) {

		props = props || {}

		var exp = props.expires

		if (typeof exp == "number" && exp) {

				var d = new Date()

				d.setTime(d.getTime() + exp*1000)

				exp = props.expires = d

		}

		if(exp && exp.toUTCString) { props.expires = exp.toUTCString() }

		value = encodeURIComponent(value)

		var updatedCookie = name + "=" + value

		for(var propName in props){

				updatedCookie += "; " + propName

				var propValue = props[propName]

				if(propValue !== true){ updatedCookie += "=" + propValue }
		}

		document.cookie = updatedCookie

}

// удаляет cookie
function deleteCookie(name) {

		setCookie(name, null, { expires: -1 })

}
/*
Аргументы:

name
название cookie
value
значение cookie (строка)
props
Объект с дополнительными свойствами для установки cookie:
expires
Время истечения cookie. Интерпретируется по-разному, в зависимости от типа:
Если число - количество секунд до истечения.
Если объект типа Date - точная дата истечения.
Если expires в прошлом, то cookie будет удалено.
Если expires отсутствует или равно 0, то cookie будет установлено как сессионное и исчезнет при закрытии браузера.
path
Путь для cookie.
domain
Домен для cookie.
secure
Пересылать cookie только по защищенному соединению.
*/
$(function() {
  function initQuestionsModal(){
    const triggers = $('.video-card__link')
    const modal = $('.questions-modal')
    const closeButton = $('.questions-modal__close')
    closeButton.on('click', function (e) {
      e.preventDefault()
      modal.fadeOut()
    });
    triggers.on('click', function (e) {
      e.preventDefault()
      modal.fadeIn()
    });
  }
  initQuestionsModal()
  function initMobileMenu(){
    const trigger = $('.burger')
    const closeButton = $('.mobile-menu__close')
    const menu = $('.mobile-menu')
    closeButton.on('click', function (e) {
      e.preventDefault()
      menu.fadeOut()
    });
    trigger.on('click', function (e) {
      e.preventDefault()
      
      menu.fadeIn()
    });
  }
  initMobileMenu()
	 function initCookie(){
	 	if(getCookie('cookie')){
	 		return
	 	}
	 	const cookieBlock = $('[data-js-cookie]')
	 	const trigger = cookieBlock.find('.cookie__trigger')
	 	trigger.on('click', function (e) {
	 		e.preventDefault()
	 		cookieBlock.hide()
	 		setCookie('cookie', 'Y')
	 	});
	 	cookieBlock.show()
	 }

	 initCookie()

	 function initScroll(){
		const triggers = $('[data-trigger]')
		triggers.on('click', function(e){
			e.preventDefault()
			const value = $(this).data('trigger')
			const section = $(`[data-anchor="${value}"]`)
			const offset = section.offset().top - 100
			$('html, body').animate({scrollTop:offset}, 300)
		})
	 }
	 initScroll()
	 function initVideoSlider(){
		const videoSlider = $('.questions__slider')
		videoSlider.owlCarousel({
			// margin:25,
			// items:3,
			 responsive: {
				 0: {
						 // items: 1,
						 autoWidth:true,
						 center:true,
						 margin:30,
				 },
				 1025:{
					 margin:25,
					 items:3,
				 }
			 }
		});
		$('.questions__button--prev').click(function() {
			videoSlider.trigger('prev.owl.carousel');
		})
		$('.questions__button--next').click(function() {
			videoSlider.trigger('next.owl.carousel');
		})
	 }
	 initVideoSlider()
	 function initProductSlider(){
		 const productSlider = $('.product__slider')
		 productSlider.owlCarousel({
			 margin:0,
			 items:1
		 });
		 $('.product__arrow--prev').click(function(e) {
			productSlider.trigger('prev.owl.carousel');
		})
		$('.product__arrow--next').click(function() {
			productSlider.trigger('next.owl.carousel');
		})
	 }
	 initProductSlider()
	 function initArticleSlider(){
		 const articleSlider = $('.articles__slider')
		 articleSlider.owlCarousel({
				responsive: {
				 0: {
						autoWidth:true,
						center:true,
						margin:30,
				 },
				 1025:{
					 margin:25,
					 items:3,
				 }
			 }
		 });
		 $('.articles__button--prev').click(function(e) {
			articleSlider.trigger('prev.owl.carousel');
		})
		$('.articles__button--next').click(function() {
			articleSlider.trigger('next.owl.carousel');
		})
	 }
	 initArticleSlider()
});
	